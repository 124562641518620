// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tools-diy-safety-system-js": () => import("./../../../src/pages/tools/diy-safety-system.js" /* webpackChunkName: "component---src-pages-tools-diy-safety-system-js" */),
  "component---src-pages-tools-free-excel-construction-templates-js": () => import("./../../../src/pages/tools/free-excel-construction-templates.js" /* webpackChunkName: "component---src-pages-tools-free-excel-construction-templates-js" */),
  "component---src-pages-tools-free-ohsa-safety-signs-for-download-js": () => import("./../../../src/pages/tools/free-ohsa-safety-signs-for-download.js" /* webpackChunkName: "component---src-pages-tools-free-ohsa-safety-signs-for-download-js" */),
  "component---src-pages-tools-hr-tool-kit-js": () => import("./../../../src/pages/tools/hr-tool-kit.js" /* webpackChunkName: "component---src-pages-tools-hr-tool-kit-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-feature-js": () => import("./../../../src/templates/feature.js" /* webpackChunkName: "component---src-templates-feature-js" */),
  "component---src-templates-integration-js": () => import("./../../../src/templates/integration.js" /* webpackChunkName: "component---src-templates-integration-js" */),
  "component---src-templates-landingpage-js": () => import("./../../../src/templates/landingpage.js" /* webpackChunkName: "component---src-templates-landingpage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */)
}

